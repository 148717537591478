<template>
  <div class="content">
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>
    <div class="row ">
      <div class="col-12 col-md-2" v-if="taxes.length > 0">
        <strong>Tarifário Local:</strong><br/>
        {{ taxes[0].numero_tarifario }}
      </div>
      <div class="col-12 col-md-2" v-if="taxes.length > 0">
        <strong>Origem/Destino:</strong><br/>
        {{ taxes[0].porto }}
      </div>

      <div class="col-12 col-md-12">
        <table class="tax-table table table-hover align-middle table-clickable">
          <thead>
          <tr>
            <th>
              Taxa
            </th>
            <th>
              Equipamento
            </th>
            <th>
              Valor Pag. Tarifário
            </th>
            <th>
              Valor Pag. Processo
            </th>
          </tr>
          </thead>
          <tbody>
          <tr :class="formatLine(tax)" v-for="(tax, index) in taxes" v-bind:key="index">
            <td>{{ tax.Taxa }}</td>
            <td>{{ tax.equipamento }}</td>
            <td>{{ tax.MoedaPagamentoSigla}} {{ tax.Valor_Pagamento_Unitario }}</td>
            <td>{{ tax.p_MoedaPagamentoSigla}} {{ tax.p_Valor_Pagamento_Unitario }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div v-if="!this.observacaoList || observacaoList <= 0">
      Nenhuma observação informada
    </div>
    <div v-else>
      <br/>
      <strong>Observações:</strong><br/><br/>
      <div class="process-observacao" v-for="(item, index) in observacaoList" v-bind:key="index">
        <div>
          <span class="usuario">{{ $util.formatUser(item.email) }}</span>
          <span class="criado_em">{{ $util.formatDateTime(item.data) }}</span>
        </div>
        <div class="observacao" v-html="$util.nl2br(item.observacao)"></div>
      </div>

    </div>
  </div>
</template>

<script>
/* eslint-disable camelcase */
// eslint-disable-next-line
import '@/assets/vendor/reflow-table/js/reflow-table.js'
import '@/assets/vendor/reflow-table/css/reflow-table.css'
// import ReportService from '@/services/ReportService'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import ReportService from '@/services/ReportService'
import ProcessService from '@/services/Process/ProcessService'

export default {
  name: 'ReportAlertsProcessosDivergenciaTaxasLocaisDetails',
  data () {
    return {
      tipoAlerta: '09203',
      isLoading: true,
      fullPage: false,
      observacaoList: [],
      taxes: []
    }
  },
  props: {
    rowData: {
      type: Object,
      required: true
    },
    rowIndex: {
      type: Number
    },
    options: {
      type: Object
    }
  },
  components: {
    Loading
  },
  created () {
    let idProcess = this.rowData.IdLogistica_House
    let _this = this
    _this.isLoading = true
    ReportService.getInfosAlert(_this.tipoAlerta, _this.rowData.IdLogistica_House).then(res => {
      _this.observacaoList = res.data.data

      ProcessService.getCompareTaxes(idProcess).then(res => {
        _this.taxes = res.data.data
      }).finally(() => {
        _this.isLoading = false
      })
    }
    )
  },
  methods: {
    formatLine (tax) {
      let activeClass = {}

      if (tax.divergencia === 'Sim') {
        activeClass.divergencia = true
      }

      return activeClass
    }
  }
}
</script>

<style scoped>
  .content {
    margin-top: 5px;
    margin-bottom: 20px;
  }

.divergencia, .divergencia:hover {
  color: #aa6708;
  background-color: #fcf8e3;
}

.divergencia:hover {
  font-weight: bold;
}
.tax-table tr:hover td {
  font-weight: bold;
}
</style>

<style>
.process-observacao .usuario, .process-observacao .criado_em {
  font-size: 12px;
}
.process-observacao .criado_em {
  padding-left: 8px;
  font-size: 12px;
}

.process-observacao .observacao {
  padding: 5px 0 15px 0;
  font-size: 14px;
  padding-bottom: 25px;
}
</style>
